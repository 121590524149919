.mobile-header-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #000;
}

.inviteTabsStyle {
  border-bottom: "solid";
  border-bottom-color: "#EEEEEE";
  border-bottom-width: 1;
  border-color: "#000000";
  z-index: 9;
}

.selectedTabstyle {
  font-size: 16;
  color: "#333333";
  font-weight: 600;
  left: 0;
  padding: 0;
}

.unselectedTabstyle {
  font-size: 16;
  color: "#333333";
  font-weight: 500;
}

.addPhotobuttonStyle {
  width: 160;
  height: 40;
  background-color: #FAFAFA;
  padding: 5px;
  font-weight: 500;
  font-size: 14;
  color: #888888;
  margin-top: 16;
}

.upload__image-wrapper {
  margin-top: 20px;
  height: 100%;
  padding: 4px;
  background: #FAFAFA;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  display: grid;
  place-content: center;
}

.upload__image-wrapper_signUp {
  height: 100%;
  padding: 4px;
  background: #FAFAFA;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  display: grid;
  place-content: center;
}

.parentField .MuiFormControl-root .MuiInputBase-root .MuiInput-input {
  padding: 8px 13px;
  font-size: 14px;
}

.customMenuItem {
  padding: 5px;
}

.customMenuItem:hover {
  background-color: #858585;
  color: #fff;
}

.textOverflow {
  max-height: 2.5em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
}

.termsConditionText {
  font-family: "SF Pro Text Regular" !important;
  font-weight: 500 !important;
  font-size: "14px" !important;
  color: #333333;
}

.termsConditionTextSub {
  font-family: "SF Pro Text Regular" !important;
  font-weight: 500 !important;
  font-size: "14px" !important;
  /* padding-left: "30px" !important; */
  color: #888888
}

.linkColor {
  color: #0085FF !important;
}