.error-message {
  color: #f4586c;
  font-size: 12px;
  font-family: "SF Pro Display Regular";
  font-weight: 400;
  opacity: 0.8;
}

.SFtext {
  font-family: "SF Pro Text Regular" !important;
}

.SFdisplay {
  font-family: "SF Pro Display Regular" !important;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
